import React, { useCallback, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import UFOVimeoPlayer from '../components/media/UFOVimeoPlayer';
import {
  Box, Container, Grid, makeStyles, Typography, Theme, createStyles, useTheme, useMediaQuery
} from '@material-ui/core';
import { IVimeoShowcase, IVimeoVideo } from '../types/vimeo';
import UFOVimeoThumbnail from '../components/media/UFOVimeoThumbnail';

interface IProps {
  data: {
    vimeoShowcase: IVimeoShowcase
    allVimeoVideo: { nodes: IVimeoVideo[] }
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      paddingLeft: '40px',
      paddingRight: '40px',
      marginBottom: '30px',
      textAlign: 'center',
      borderBottom: '1px solid #e5e5e5'
    }
  })
);

const VimeoShowcaseTemplate = ({ data }: IProps): JSX.Element => {

  const classes = useStyles();
  const videos = data.allVimeoVideo.nodes;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [playedVideo, setPlayedVideo] = React.useState<IVimeoVideo | null>(null);
  const handleVideoPlay = useCallback((vimeo: IVimeoVideo) => { setPlayedVideo(vimeo); }, [setPlayedVideo]);

  useEffect(() => {
    if (isDesktop && videos.length > 0) {
      setPlayedVideo(videos[0]);
    }
  }, [isDesktop, videos]);

  return (
    <>
      <Helmet title={data.vimeoShowcase.name} />
      <Container maxWidth="lg">
        <Box className={classes.title} >
          <Typography component="div" variant="h5">{data.vimeoShowcase.name}</Typography>
          {data.vimeoShowcase.description && (<Typography variant="subtitle1" color="textSecondary" component="div">{data.vimeoShowcase.description}</Typography>)}
        </Box>
        <Grid xs={12} container spacing={2} >

          {isDesktop && playedVideo && (
            <Grid
              xs={12} item key={playedVideo.uri}
            >
              <UFOVimeoPlayer vimeoVideo={playedVideo} />
            </Grid>
          )}
          {videos.map((video, index) => (
            <Grid
              xs={12} sm={6} md={4}
              lg={3} item key={video.uri}
            >
              {(!isDesktop && playedVideo && video.uri === playedVideo.uri) && (
                <UFOVimeoPlayer vimeoVideo={playedVideo} />
              )}
              {(isDesktop || video.uri !== playedVideo?.uri) && (
                <UFOVimeoThumbnail

                  vimeoVideo={video}
                  //disabled={video.uri === playedVideo.uri}
                  handleOpen={handleVideoPlay}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export const query = graphql`
  query($slug: String!) {
    vimeoShowcase(fields: {slug: {eq: $slug}}) {
        name
        description
      }
    allVimeoVideo(filter: {showcase: {eq: $slug}}) {
        nodes {
          uri
          name
          thumbnailImg {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link
          duration
          description
          language
          type
          height
          width
        }
      }
  }
`;

export default VimeoShowcaseTemplate;
