import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { IVimeoVideo } from '../../types/vimeo';
import Img from 'gatsby-image';
import { CardActionArea, createStyles, Grid, makeStyles } from '@material-ui/core';

interface IProps {
  vimeoVideo: IVimeoVideo
  disabled?: boolean
  handleOpen?: (vimeoVideo: IVimeoVideo) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    media: {},
    thumbnail: {}
  })
);

const UFOVimeoThumbnail = ({ vimeoVideo, disabled = false, handleOpen }: IProps) => {
  const classes = useStyles();

  return (
    <Grid item key={vimeoVideo.uri} >
      <Card className={classes.root}>
        <CardActionArea disabled={disabled} onClick={() => { handleOpen && handleOpen(vimeoVideo); }}>
          <CardMedia
            className={classes.media}
            title={vimeoVideo.name}
          >
            <Img
              alt={vimeoVideo.description} title={vimeoVideo.name}
              fluid={vimeoVideo.thumbnailImg.childImageSharp.fluid} className={classes.thumbnail}
            />
          </CardMedia>
          <CardContent>
            <Typography variant="caption" >{vimeoVideo.name}</Typography>
          </CardContent>

        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default UFOVimeoThumbnail;
