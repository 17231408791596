import React, { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { IVimeoVideo } from '../../types/vimeo';
import Img from 'gatsby-image';
import { CircularProgress, Grid, LinearProgress, Typography } from '@material-ui/core';

interface IProps {
  vimeoVideo: IVimeoVideo

}

const UFOVimeoPlayer = ({ vimeoVideo }: IProps): JSX.Element => {

  const [ready, setReady] = useState(false);

  return (
    <Grid xs={12} container>
      <Grid item xs={vimeoVideo.description ? 6 : 12} >
        <Typography variant="h5">{vimeoVideo.name}</Typography>
      </Grid>
      {ready === false && (
        <Grid item xs={12} >
          <LinearProgress />
        </Grid>
      )}
      {ready === true && vimeoVideo.description && (
        <Grid item xs={6} >
          <Typography variant="subtitle1" color="textSecondary" align="right">{vimeoVideo.description}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {ready === false && (
          <>
            <Img
              alt={vimeoVideo.description} title={vimeoVideo.name}
              fluid={vimeoVideo.thumbnailImg.childImageSharp.fluid}
            />

          </>
        )}
        <Vimeo
          style={{ display: ready ? undefined : 'none' }}
          video={vimeoVideo.link} responsive={true} autoplay
          onReady={() => { setReady(true); }}
        />
      </Grid>
    </Grid >
  );
};

export default UFOVimeoPlayer;
